export const muiTableoptions = {
    rowsPerPage: 25,
    viewColumns: false,
    searchPlaceholder: "Введіть більше 2 символів для пошуку...",
    rowsPerPageOptions: [25, 50, 100, 500],
    filterType: 'checkbox',
    download: false,
    selectableRows: "none",
    print: false,
    rowHover: true,
    textLabels: {
        body: {
          noMatch: "Записів не знайдено",
          toolTip: "Сортування",
          columnHeaderTooltip: column => `Сортування по ${column.label}`
        },
        pagination: {
          next: "Наступна сторінка",
          previous: "Попередня сторінка",
          rowsPerPage: "Записів на сторінці:",
          displayRows: "із",
        },
        toolbar: {
          search: "Пошук",
          viewColumns: "Стовпці на екрані",
          filterTable: "Фільтр",
        },
        filter: {
          all: "Всі",
          title: "Фільтр",
          reset: "Скинути",
        },
        viewColumns: {
          title: "Показати стовпці",
          titleAria: "Показати/Приховати стовпці",
        },
        selectedRows: {
          text: "Записів вибрано",
          delete: "Видалити",
          deleteAria: "Видалити вибрані записи",
        },
      }
};