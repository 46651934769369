import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { getWeapon, weaponConfirm } from '../common/data-fetch';
import MUIDataTable from "mui-datatables";
import { muiTableoptions } from "../common/constants"

export class Weapon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert() {
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        this.setState({
            items: await getWeapon(),
            dataIsLoaded: true,
        });
    }

    async onConfirmPayHandle(id) {
        try {
            if (id) {
                const editResponse = await weaponConfirm(id);
                if (editResponse.status === 200) {
                    this.setState({
                        items: await getWeapon(),
                        notifyMessageSuccess: "Платіж успішно підтверджено!",
                    });
                    this.onShowSuccessAlert();
                }
            } else {
                this.onShowDangerAlert();
            }
        } catch (e) {
            this.onShowDangerAlert();
        }
    }

    render() {
        const columns = [
            {
                name: "phone",
                label: "Телефон",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "birthDate",
                label: "Дата народження",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "fullName",
                label: "ПІБ",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "startDate",
                label: "Дата початку",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    
                },
            },
            {
                name: "endDate",
                label: "Дата завершення",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
                
            },
            {
                name: "passport",
                label: "Паспорт ",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "policyNum",
                label: "Номер полісу",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "summ",
                label: "Сума премії",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "partner",
                label: "Партнер",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "status",
                label: "Статус оплати",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "createDate",
                label: "Дата створення",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
        ];
        if (!this.state.dataIsLoaded) return
        <div align="center">
            <Spinner animation="grow" />
        </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <MUIDataTable
                    title={"Поліси власників зброї"}
                    data={this.state.items}
                    columns={columns}
                    options={muiTableoptions}
                />
            </div>
        );
    }
}

export default Weapon;