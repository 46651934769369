import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Weapon from "./Weapon";
import PolicyPool from "./PolicyPool";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TableError from './TableError';
import LoginContext from '../common/context';

export class NavbarInterplus extends Component {
  static contextType = LoginContext;

  render() {
    return (
      <div>
          <BrowserRouter>
            <Navbar bg="dark" variant={"dark"} expand="xxl">
              <Container>
                <Navbar.Brand as={Link} to={"/weapon"}>Just Ride Bot manager</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link as={Link} to={"/weapon"}>Власників зброї</Nav.Link>
                    <Nav.Link as={Link} to={"/policy-pools"}>Номери полісів</Nav.Link>
                    <Nav.Link onClick={ () => this.context(false)}>Вийти</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <Container>
              <Row>
                <Col>
                <TableError onLogout={ () => this.context(false) } fallback={"Помилка під час отримання даних або завершився строк дії токену! Спробуйте повторно авторизуватись"}>
                    <Routes>
                      <Route path='/weapon' element={<Weapon onLogout={ () => this.context(false) }/>} />
                      <Route path='/policy-pools' element={<PolicyPool onLogout={ () => this.context(false) }/>} />
                      <Route path='*' element={<Navigate to="/weapon" />} />
                    </Routes>
                  </TableError>
                </Col>
              </Row>
            </Container>
          </BrowserRouter>
      </div>
    );
  }
}

export default NavbarInterplus;
