import { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import LoginContext from '../common/context'

class TableError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }
    static contextType = LoginContext;

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, stack) {
        this.props.onLogout(true);
        console.error("componentDidCatch", error, stack);
    }

    render() {
        return this.state.error ?
        <Alert align="center" key="danger" variant="danger" show={true}>
            {this.props.fallback}
        </Alert>
        :
        this.props.children;
    }
}

export default TableError;