import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const dropdownData = [
    { event: "EXTREEME", name: "Екстрим" },
    { event: "TOUR", name: "Туристичні" },
];

class PolicyPoolFormModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            type: dropdownData[0].event,
            name: "",
            selected: dropdownData[0].name,
        };
    }
    onShowHandle() {
        this.setState({
            id: this.props.id ? this.props.id : null,
            type: this.props.type ? this.props.type : dropdownData[0].event,
            name: this.props.name,
            selected: this.props.type ? this.getSelectedFromEvent(this.props.type) : dropdownData[0].name,
        });
    }

    getDropObjectByEvent(e) {
        return dropdownData.find((elem) => elem.event === e);
    }

    getSelectedFromEvent(e) {
        const object = this.getDropObjectByEvent(e);
        return object.name;
    }

    handleClose(show) {
        if (!show) {
            this.props.onClickNo(show);
        }
    }

    handleSelect(e) {
        const textValueObject = this.getDropObjectByEvent(e);
        this.setState({ type: e, selected: textValueObject.name });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onClickYes(this.state);
        this.handleClose(false);
    }

    render() {
        return (
            <Modal onShow={(e) => this.onShowHandle(e)} show={this.props.show} onHide={() => this.handleClose()}>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form.Group className="mb-3" controlId="typeDrop">
                            <Form.Label>Тип:</Form.Label>
                            <DropdownButton id="dropdown-filter" title={this.state.selected} onSelect={(e) => this.handleSelect(e)}>
                                {dropdownData.map((elem, index) => (
                                    <Dropdown.Item key={index+1} eventKey={elem.event}>{elem.name}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                    </Form.Group>
                        <Form.Group className="mb-3" controlId="nameText">
                            <Form.Label>Імʼя:</Form.Label>
                            <Form.Control
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: e.target.value })}
                                type="text"
                                required
                                placeholder="Введіть назву полісу" />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Group className="mb-3" controlId="cancel">
                            <Button variant="secondary" onClick={() => this.handleClose(false)}>
                                Назад
                            </Button>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="submit">
                            <Button type="submit" variant="primary">
                                Зберегти
                            </Button>
                        </Form.Group>

                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default PolicyPoolFormModal;