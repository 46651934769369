import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ConfirmModal extends Component {
    handleClose(confirm) {
        if (confirm) {
            this.props.onClickYes(this.props.action, this.props.id);
        } else {
            this.props.onClickNo(confirm);
        }
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.handleClose(false)}>
                        Ні
                    </Button>
                    <Button variant="primary" onClick={() => this.handleClose(true)}>
                        Так
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmModal;