import { getFormattedDate, getFormattedTime, getFormattedSumm, formatPhoneNumber } from "../common/helper"

const baseUrl = process.env.REACT_APP_API_URL;

function getTokenFromLocalStorage() {
    return localStorage.getItem("token");
}

function getRequestHeaders() {
    return {
        'Content-Type': 'application/json',
        'Authorization': getTokenFromLocalStorage(),
    };
}

async function getRequest(endPoint) {
    try {
        const data = await fetch(`${baseUrl}/api/${endPoint}`, { headers: getRequestHeaders() });
        if (data && data.status === 200) {
            const response = await data.json();
            return response;
        } else {
            localStorage.removeItem("token");
        }
    } catch (e) {
        console.log(e)
    }
}

async function getBaseRequest(endPoint, body, method) {
    try {
        const data = await fetch(`${baseUrl}/api/${endPoint}`, { body: JSON.stringify({ ...body }), method, headers: getRequestHeaders() });
        if (data && data.status === 200) {
            return data;
        } else {
            localStorage.removeItem("token");
        }
    } catch (e) {
         console.log(e)
    }
}

async function postRequest(endPoint, body) {
    try {
        return getBaseRequest(endPoint, body, "POST");
    } catch (e) {
         console.log(e)
    }
}

async function putRequest(endPoint, body) {
    try {
        return getBaseRequest(endPoint, body, "PUT");
    } catch (e) {
         console.log(e)
    }
}

async function deleteRequest(endPoint, body) {
    try {
        return getBaseRequest(endPoint, body, "DELETE");
    } catch (e) {
         console.log(e)
    }
}

export async function extreemeConfirm(id) {
    try {
        const data = await postRequest(`extreeme-confirm/${id}`, {});
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function weaponConfirm(id) {
    try {
        const data = await postRequest(`weapon-confirm/${id}`, {});
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function deletePolicyPool(id) {
    try {
        const data = await deleteRequest(`policy-pool/${id}`, {});
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function deleteUser(id) {
    try {
        const data = await deleteRequest(`users/${id}`, {});
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function putPolicyPool(body) {
    try {
        const data = await putRequest(`policy-pool`, body);
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function editPolicyPool(id, body) {
    try {
        const data = await postRequest(`policy-pool/${id}`, body);
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function sendToUser(id, body) {
    try {
        const data = await postRequest(`send/${id}`, body);
    
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function auth(login, password) {
    try {
        const data = await postRequest("auth", { login, password });
        return {
            status: data.status,
            json: await data.json(),
        };
    } catch (e) {
         console.log(e)
    }
}

export async function getAdultCritical() {
    try {
        const data = [];
        const dataFetch = await getRequest("adult-critical");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        age: elem.age,
                        fullName: elem.fullName,
                        gender: elem.gender,
                        period: elem.period,
                        disease: elem.disease,
                        finalSumm: getFormattedSumm(elem.finalSumm),
                        summ: getFormattedSumm(elem.summ),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getAdultSaving() {
    try {
        const data = [];
        const dataFetch = await getRequest("adult-saving");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        age: elem.age,
                        fullName: elem.fullName,
                        sex: elem.sex,
                        targetRangeText: elem.targetRangeText,
                        summ: getFormattedSumm(elem.summ),
                        perYearPolicySumm: getFormattedSumm(elem.perYearPolicySumm),
                        perYearSumm: getFormattedSumm(elem.perYearSumm),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getAccident() {
    try {
        const data = [];
        const dataFetch = await getRequest("accident");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        age: elem.age,
                        gender: elem.gender,
                        hasDangerWork: elem.hasDangerWork ? "Так" : "Ні",
                        isDriveMoto: elem.isDriveMoto ? "Так" : "Ні",
                        injurySumm: elem.isIncludedInjurySumm ? getFormattedSumm(elem.injurySumm) : `${getFormattedSumm(elem.injurySumm)} (виключена)`,
                        disabilitySumm: elem.isIncludedDisabilitySumm ? getFormattedSumm(elem.disabilitySumm) : `${getFormattedSumm(elem.disabilitySumm)} (виключена)`,
                        deathSumm: elem.isIncludedDeathSumm ? getFormattedSumm(elem.deathSumm) : `${getFormattedSumm(elem.deathSumm)} (виключена)`,
                        totalSumm: getFormattedSumm(elem.totalSumm),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getFeedback() {
    try {
        const data = [];
        const  dataFetch = await getRequest("feedbacks");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        text: elem.text,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getInsuredEvents() {
    try {
        const data = [];
        const  dataFetch = await getRequest("insured-events");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getProperty() {
    try {
        const data = [];
        const dataFetch = await getRequest("property");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        birthDate: getFormattedDate(elem.customerBirthday),
                        ingoNum: elem.ingoNum,
                        status: elem.status === "PENDING" ? "Не підтверджено" : elem.status === "CONFIRMED" ? "Підтверджено" : elem.status === "COMPLETE" ? "Оплачено" : elem.status === "GENERATED" ? "Відправлено PDF" : "Нема відповіді від Ingo",
                        buildType: elem.p6id === "72" ? "Квартира" : "Будинок",
                        fullName: `${elem.customerSecondName} ${elem.customerFirstName} ${elem.customerThirdName}`,
                        startDate: getFormattedDate(elem.startDate),
                        cover: getFormattedSumm(elem.cover),
                        amount: getFormattedSumm(elem.amount),
                        phone: formatPhoneNumber(elem.phone[0]),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getChildrenCritical() {
    try {
        const data = [];
        const dataFetch = await getRequest("children-critical");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        age: elem.age,
                        parentName: elem.parentName,
                        name: elem.name,
                        period: elem.period,
                        criticalAilmentSumm: getFormattedSumm(elem.criticalAilmentSumm),
                        injuriSumm: getFormattedSumm(elem.injuriSumm),
                        surgerySumm: getFormattedSumm(elem.surgerySumm),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getChildrenSaving() {
    try {
        const data = [];
        const dataFetch = await getRequest("children-saving");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        childAge: elem.childAge,
                        childName: elem.childName,
                        adultName: elem.adultName,
                        adultAge: elem.adultAge,
                        gender: elem.gender,
                        period: elem.period,
                        targetSumm: getFormattedSumm(elem.targetSumm),
                        summPerYear: getFormattedSumm(elem.summPerYear),
                        policySummPerYear: getFormattedSumm(elem.policySummPerYear),
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}
export async function getExtreeme() {
    try {
        const data = [];
        const  dataFetch = await getRequest("extreeme");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                console.log(elem);
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone),
                        birthDate: getFormattedDate(elem.birthDate),
                        fullName: elem.fullName,
                        endDate: elem.endDate ? getFormattedDate(elem.endDate) : "n/a",
                        startDate: getFormattedDate(elem.startDate),
                        passport: elem.passport,
                        policyNum: elem.policyNum,
                        childBirthDate: getFormattedDate(elem.childBirthDate) ?? "n/a",
                        childFullName: elem.childFullName ?? "n/a",
                        policySumm: getFormattedSumm(elem.policySumm),
                        summ: getFormattedSumm(elem.summ),
                        partner: elem.partner,
                        status: elem.status === "PENDING" ? "Очікуєтсья" : elem.status === "COMPLETE" ? "Успішна" : "Строк вийшов",
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}
export async function getWeapon() {
    try {
        const data = [];
        const  dataFetch = await getRequest("weapon");
        if (dataFetch) {
            for (const elem of dataFetch.policies) {
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone[0]),
                        birthDate: getFormattedDate(elem.birthDate),
                        fullName: elem.fullName,
                        endDate: elem.endDate ? getFormattedDate(elem.endDate) : "n/a",
                        startDate: getFormattedDate(elem.startDate),
                        passport: elem.passport,
                        policyNum: elem.policyNum,
                        summ: getFormattedSumm(elem.summ),
                        partner: elem.partner,
                        status: elem.status === "PENDING" ? "Очікуєтсья" : elem.status === "COMPLETE" ? "Успішна" : "Строк вийшов",
                        createDate: getFormattedDate(elem.createDate) + " " + getFormattedTime(elem.createDate),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}

export async function getTour() {
    try {
        return getRequest("tour");
    } catch (e) {
         console.log(e)
    }
}

export async function getPoolPolicy() {
    try {
        return getRequest("policy-pool");
    } catch (e) {
         console.log(e)
    }
}

export async function getUsers() {
    try {
        const data = [];
        const  dataFetch = await getRequest("users");
        if (dataFetch) {
            for (const elem of dataFetch.clients) {
                const lastName = elem.lastName && elem.lastName !== "undefined" ? elem.lastName + " ": "";
                const firstName = elem.firstName && elem.firstName !== "undefined" ? elem.firstName : "";

                const fullName = lastName + firstName;
                data.push(
                    {
                        phone: formatPhoneNumber(elem.phone),
                        fullName: fullName,
                        chatId: elem.chatId,
                        partner: elem.partner,
                        createdAt: getFormattedDate(elem.createdAt) + " " + getFormattedTime(elem.createdAt),
                    },
                );
            }
        }

        return data;
    } catch (e) {
         console.log(e)
    }
}