export function getFormattedDate(date) {
    let response = "n/a";

    if (date) {
        const ye = new Intl.DateTimeFormat("de-DE", { year: "numeric" }).format(new Date(date));
        const mo = new Intl.DateTimeFormat("de-DE", { month: "2-digit" }).format(new Date(date));
        const da = new Intl.DateTimeFormat("de-DE", { day: "2-digit" }).format(new Date(date));

        response = `${da}.${mo}.${ye}`;
    }

    return response;
}

export function getFormattedSumm(value) {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getFormattedTime(date) {
    let response = "";

    if (date) {
        const hours = new Date(date).getHours();
        const min =  new Date(date).getMinutes();

        response = `${hours}:${(new Date(date).getMinutes()<10?'0':'') + min}`;
    }

    return response;
}

export function formatPhoneNumber(phoneNumber) {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let check = cleaned.length;
    if (check === 12) {
        let formatted = cleaned.replace(/^(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, "$1 $2 $3 $4 $5");

        return formatted;
    } else {
        return phoneNumber;
    }
}