import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { getAdultSaving } from '../common/data-fetch';
import MUIDataTable from "mui-datatables";
import { muiTableoptions } from "../common/constants"

export class AdultSaving extends Component {
   
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert() {
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        this.setState({
            items: await getAdultSaving(),
            dataIsLoaded: true,
        });
    }

    render() {
        const columns = [
            {
                name: "phone",
                label: "Телефон",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                },
            },
            {
                name: "age",
                label: "Вік",
                options: {
                    filter: false,
                    sort: false,
                },
            },
            {
                name: "fullName",
                label: "ПІБ",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "sex",
                label: "Стать",
                options: {
                    filter: true,
                    sort: false,
                    
                },
            },
            {
                name: "targetRangeText",
                label: "Період отримання виплати",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: false,
                    sort: false,
                },
                
            },
            {
                name: "summ",
                label: "Бажана сума",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                },
            },
            {
                name: "perYearPolicySumm",
                label: "Премія",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                },
            },
            {
                name: "perYearSumm",
                label: "Сума накопичень в рік",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                },
            },
            {
                name: "partner",
                label: "Партнер",
                options: {
                    filter: true,
                    sort: false,
                },
            },
            {
                name: "createdAt",
                label: "Дата створення",
                options: {
                    filter: false,
                    sort: false,
                },
            },
        ];
        if (!this.state.dataIsLoaded) return
            <div align="center">
                <Spinner animation="grow" />
            </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <MUIDataTable
                    title={"Накопичення (дорослі)"}
                    data={this.state.items}
                    columns={columns}
                    options={muiTableoptions}
                />
            </div>
        );
    }
}

export default AdultSaving;