import './App.css';
import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarAdmin from "./components/NavbarAdmin"
import NavbarInterplus from "./components/NavbarInterplus"
import NavbarExtreeme from "./components/NavbarExtreeme"
import NavbarExtreemeAccident from "./components/NavbarExtreemeAccident"
import NavbarFullSubscription from "./components/NavbarFullSubscription"
import NavbarSubscription from "./components/NavbarSubscription"
import Login from './components/Login';
import { LoginProvider } from './common/context'

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: false,
      token: null,
      role: null,
    }
  }

  handleLogOut(value) {
    if (!value) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
    }
    this.setState({
      login: value,
    });
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    this.setState({
      login: !!(token && role),
      role,
      token,
    });
  }

  render() {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (!this.state.login && (!token || !role)) {
      return (
        <LoginProvider value={(e) => this.handleLogOut(e)}>
          <Login/>
        </LoginProvider>
      )
    } else {
      switch (role) {
        case "ADMIN":
          return (
            <LoginProvider value={(e) => this.handleLogOut(e)}>
              <NavbarAdmin/>
            </LoginProvider>
          );
        case "INTERPLUS":
          return (
            <LoginProvider value={(e) => this.handleLogOut(e)}>
              <NavbarInterplus/>
            </LoginProvider>
          );
        case "EXTREEME":
          return (
            <LoginProvider value={(e) => this.handleLogOut(e)}>
              <NavbarExtreeme/>
            </LoginProvider>
          );
          default:
          case "SUBSCRIPTION":
            return (
              <LoginProvider value={(e) => this.handleLogOut(e)}>
                <NavbarSubscription/>
              </LoginProvider>
            );
          case "FULL_SUBSCRIPTION":
            return (
              <LoginProvider value={(e) => this.handleLogOut(e)}>
                <NavbarFullSubscription/>
              </LoginProvider>
            );
          case "EXTREEME_ACCIDENTS":
            return (
              <LoginProvider value={(e) => this.handleLogOut(e)}>
                <NavbarExtreemeAccident/>
              </LoginProvider>
            );
          
      }
    }
  }
}

export default App;
