import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import PolicyPoolTable from "./PolicyPoolTable"
import PolicyPoolFormModal from "./PolicyPoolFormModal"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import { deletePolicyPool, editPolicyPool, getPoolPolicy, putPolicyPool } from '../common/data-fetch';

const filterButton = [
    { title: "ALL", name: "Всі" },
    { title: "EXTREEME", name: "Екстрим" },
    { title: "TOUR", name: "Туристичні" },
    { title: "WEAPON", name: "Власники зброї" },
];

export class PolicyPool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFilterTitle: "Всі",
            editName: "",
            editId: null,
            editType: "",
            showFormModal: false,
            items: [],
            notFilteredItems: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert(e) {
        console.log(e);
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        try {
            const json = await getPoolPolicy()
    
            this.setState({
                items: await getPoolPolicy(),
                notFilteredItems: json,
                dataIsLoaded: true,
            });
        } catch (e) {
            throw e;
        }
    }

    async handleClick(action, id) {
        try {
            if (action === "delete") {
                const delResponse = await deletePolicyPool(id);
                if (delResponse.status === 200) {
                    const items = { policies: this.state.items.policies.filter((item) => item._id !== id) };
                    const notFilteredItems = { policies: this.state.notFilteredItems.policies.filter((item) => item._id !== id) };
                    this.setState({
                        items: items,
                        notFilteredItems,
                        notifyMessageSuccess: "Запис успішно видалено!"
                    });
                    this.onShowSuccessAlert();
                } else {
                    this.onShowDangerAlert();
                }
            }
        } catch (e) {
            this.onShowDangerAlert(e);
        }
    }
    async handleFormSubmit(e) {
        try {
            if (e.id) {
                const editResponse = await editPolicyPool(e.id, { id: e.id, type: e.type, name: e.name });
                if (editResponse.status === 200) {
                    const json = await getPoolPolicy();
                    const items = {
                        policies: json.policies.filter((elem) => elem.type === e.type),
                    };
                    this.setState({
                        items,
                        notFilteredItems: json,
                        notifyMessageSuccess: "Запис успішно змінено!",
                    });
                    this.onShowSuccessAlert();
                } else {
                    this.onShowDangerAlert();
                }
            } else {
                const createResponse = await putPolicyPool({ type: e.type, name: e.name });
                if (createResponse.status === 200) {
                    const json = await getPoolPolicy();
                    const items = {
                        policies: json.policies.filter((elem) => elem.type === e.type),
                    };
                    this.setState({
                        items,
                        notFilteredItems: json,
                        notifyMessageSuccess: "Запис успішно створено!",
                    });
                    this.onShowSuccessAlert();
                } else {
                    this.onShowDangerAlert();
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    onEditHandle(type, name, id) {
        this.setState({
            editName: name,
            editId: id,
            editType: type,
            showFormModal: true,
        });
    }

    onCreateHandle(e) {
        this.setState({
            editName: "",
            editType: null,
            editId: null,
            showFormModal: true,
        });
    }

    handleDropDownChange(e) {
        let items = {
            policies: this.state.notFilteredItems.policies
        };

        if (e.target.title !== "ALL") {
            items = {
                policies: this.state.notFilteredItems.policies.filter((elem) => elem.type === e.target.title),
            };

        }
        this.setState({
            items,
        });
    }

    getDropObjectByEvent(e) {
        return filterButton.find((elem) => elem.title === e)
    }

    handleSelect(e) {
        const textValueObject = this.getDropObjectByEvent(e);

        let items = {
            policies: this.state.notFilteredItems.policies
        };

        if (e !== "ALL") {
            items = {
                policies: this.state.notFilteredItems.policies.filter((elem) => elem.type === e),
            };

        }

        this.setState({ items, selectedFilterTitle: textValueObject.name });
    }

    render() {
        if (!this.state.dataIsLoaded)
            return
        <div align="center">
            <Spinner animation="grow" />
        </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <PolicyPoolFormModal
                    title={"Створення запису"}
                    onClickYes={(e) => this.handleFormSubmit(e)}
                    onClickNo={(e) => this.setState({ showFormModal: false })}
                    show={this.state.showFormModal}
                    name={this.state.editName}
                    type={this.state.editType}
                    id={this.state.editId}
                />
                <h4 align="center">Доступний пул полісів</h4>
                <Container>
                    <Row>
                        <Col xs={6}>
                            <DropdownButton id="dropdown-filter" title={this.state.selectedFilterTitle} onSelect={(e) => this.handleSelect(e)}>
                                {filterButton.map((elem, index) => (
                                    <Dropdown.Item key={index + 1} eventKey={elem.title}>{elem.name}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                        <Col align="right" xs={6}>
                            <Button onClick={(e) => this.onCreateHandle(e)} variant="primary"> + Новий</Button>{' '}
                        </Col>
                    </Row>
                    <Row>
                        <PolicyPoolTable onEditHandle={(type, name, id) => this.onEditHandle(type, name, id)} items={this.state.items} onClick={(action, index, message) => this.handleClick(action, index, message)} />
                    </Row>
                </Container>
            </div>
        );
    }
}

export default PolicyPool;