import React, { Component } from 'react';
import { auth } from '../common/data-fetch';
import "./App.css"
import LoginContext from '../common/context'

export class Login extends Component {
    static contextType = LoginContext;

    async handleSubmit(e) {
        try {
            e.preventDefault();
            const response = await auth(e.target[0].value, e.target[1].value);
            if (response && response.status === 200 && response.json && response.json.token) {
                localStorage.setItem("token", response.json.token);
                localStorage.setItem("role", response.json.role);
                localStorage.setItem("partner", response.json.partner);
                this.context(true);
            }
        } catch (e) {
            throw e;
        }
    }

    render() {
        return (
            <div className="Auth-form-container">
                <form onSubmit={(e) => this.handleSubmit(e)} className="Auth-form">
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">Авторизація</h3>
                        <div className="form-group mt-3">
                            <label>Логін</label>
                            <input
                                required
                                type="text"
                                className="form-control mt-1"
                                placeholder="Введіть ваш логін"
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label>Пароль</label>
                            <input
                                required
                                type="password"
                                className="form-control mt-1"
                                placeholder="Введіть ваш пароль"
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-primary">
                                Увійти
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default Login;

