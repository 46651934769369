import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import Users from './Users';
import Error from './Error';
import Home from "./Home";
import Extreeme from "./Extreeme";
import Weapon from "./Weapon";
import AdultSaving from "./AdultSaving";
import ChildrenSaving from "./ChildrenSaving";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdultCritical from './AdultCritical';
import ChildrenCritical from './ChildrenCritical';
import Accident from './Accident';
import Property from './Property';
import TableError from './TableError';
import LoginContext from '../common/context';

export class NavbarAdmin extends Component {
    static contextType = LoginContext;

    render() {
        return (
            <div>
                <BrowserRouter>
                    <Navbar bg="dark" variant={"dark"} expand="xxl">
                        <Container>
                            <Navbar.Brand as={Link} to={"/users"}>{`Just Ride Bot manager [${localStorage.getItem("partner")}]`}</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link as={Link} to={"/users"}>Користувачі</Nav.Link>
                                    <NavDropdown title="Поліси" id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to={"/property"}>
                                            Страхування житла
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/extreeme"}>
                                            Одноденні-екстрим
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/weapon"}>
                                            Власників зброї
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/accident"}>
                                            Нещасні випадки
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/adult-saving"}>
                                            Накопичення (дорослі)
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/adult-critical"}>
                                            Критичні хвороби (дорослі)
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/children-saving"}>
                                            Накопичення (дитячі)
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/children-critical"}>
                                            Критичні хвороби (дитячі)
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link onClick={() => this.context(false)}>Вийти</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <Container>
                        <Row>
                            <Col>
                                <TableError onLogout={() => this.context(false)} fallback={"Помилка під час отримання даних або завершився строк дії токену! Спробуйте повторно авторизуватись"}>
                                    <Routes>
                                        <Route path='/property' element={<Property onLogout={() => this.context(false)} />} />
                                        <Route path='/extreeme' element={<Extreeme onLogout={() => this.context(false)} />} />
                                        <Route path='/weapon' element={<Weapon onLogout={() => this.context(false)} />} />
                                        <Route path='/accident' element={<Accident onLogout={() => this.context(false)} />} />
                                        <Route path='/adult-saving' element={<AdultSaving onLogout={() => this.context(false)} />} />
                                        <Route path='/adult-critical' element={<AdultCritical onLogout={() => this.context(false)} />} />
                                        <Route path='/children-saving' element={<ChildrenSaving onLogout={() => this.context(false)} />} />
                                        <Route path='/children-critical' element={<ChildrenCritical onLogout={() => this.context(false)} />} />
                                        <Route index element={<Home onLogout={() => this.context(false)} />} />
                                        <Route path='/users' element={<Users onLogout={() => this.context(false)} />} />
                                        <Route path='*' element={<Error />} />
                                    </Routes>
                                </TableError>
                            </Col>
                        </Row>
                    </Container>
                </BrowserRouter>
            </div>
        );
    }
}

export default NavbarAdmin;
