import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import MUIDataTable from "mui-datatables";
import { getAccident } from '../common/data-fetch';
import { muiTableoptions } from "../common/constants"

export class Accident extends Component {
   
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert() {
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        this.setState({
            items: await getAccident(),
            dataIsLoaded: true,
        });
    }

    render() {
        const columns = [
            {
                name: "phone",
                label: "Телефон",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { whiteSpace: 'nowrap', textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "age",
                label: "Вік",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "gender",
                label: "Стать",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "hasDangerWork",
                label: "Небезпечна професія",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    
                },
            },
            {
                name: "isDriveMoto",
                label: "Їздить на мото",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "injurySumm",
                label: "Сума, тілесні",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "disabilitySumm",
                label: "Сума, інвалідність",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "deathSumm",
                label: "Сума, смерть",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "totalSumm",
                label: "Річна вартість",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "partner",
                label: "Партнер",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "createdAt",
                label: "Дата створення",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: false,
                    sort: false,
                },
            },
        ];

        if (!this.state.dataIsLoaded) return
            <div align="center">
                <Spinner animation="grow" />
            </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <MUIDataTable
                    title={"Нещасні випадки"}
                    data={this.state.items}
                    columns={columns}
                    options={muiTableoptions}
                />
            </div>
        );
    }
}

export default Accident;