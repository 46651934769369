import React, { Component } from 'react';

class Error extends Component {
    render() {
        return (
            <div>
                Сторінка не знайдена!
            </div>
        );
    }
}

export default Error;