import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { getProperty } from '../common/data-fetch';
import MUIDataTable from "mui-datatables";
import { muiTableoptions } from "../common/constants"

export class Property extends Component {
   
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert() {
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        this.setState({
            items: await getProperty(),
            dataIsLoaded: true,
        });
    }

    render() {
        const columns = [
            {
                name: "phone",
                label: "Телефон",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "fullName",
                label: "ПІБ страхувальника",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "birthDate",
                label: "Дата народження",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "buildType",
                label: "Тип житла",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "cover",
                label: "Сума покриття",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "amount",
                label: "Премія",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "ingoNum",
                label: "Номер полісу",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center', whiteSpace: 'nowrap' } }),
                },
            },
            {
                name: "status",
                label: "Статус",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "partner",
                label: "Партнер",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => ({ style: { textAlign: 'center'} }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "createdAt",
                label: "Дата створення",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center'} }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: false,
                    sort: true,
                },
            },
        ];
        if (!this.state.dataIsLoaded) return
            <div align="center">
                <Spinner animation="grow" />
            </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <MUIDataTable
                    title={"Страхування житла"}
                    data={this.state.items}
                    columns={columns}
                    options={muiTableoptions}
                />
            </div>
        );
    }
}

export default Property;
