import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import MUIDataTable from "mui-datatables";
import { deleteUser, getUsers, sendToUser } from '../common/data-fetch';
import { muiTableoptions } from "../common/constants"

export class Users extends Component {
   
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            dataIsLoaded: false,
            nofifySuccessVisible: false,
            nofifyDangerVisible: false,
            notifyMessageSuccess: "",
            notifyMessageDanger: "Під час виконання запиту виникла помилка!",
        };
    }

    onShowSuccessAlert() {
        this.setState({ nofifySuccessVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ notifyMessageSuccess: "", nofifySuccessVisible: false })
                }, 2000)
            });
    }

    onShowDangerAlert() {
        this.setState({ nofifyDangerVisible: true },
            () => {
                window.setTimeout(() => {
                    this.setState({ nofifyDangerVisible: false })
                }, 2000)
            });
    }

    async componentDidMount() {
        try {
            this.setState({
                items: await getUsers(),
                dataIsLoaded: true,
            });
        } catch (e) {
            console.log(e);
        }
    }

    async handleClick(action, id, message){
        try {
            if (action === "delete") {
                const delResponse = await deleteUser(id);
                if (delResponse.status === 200) {
                    const items = { clients: this.state.items.clients.filter((item) => item._id !== id) };
                    this.setState({
                        items: items,
                        dataIsLoaded: true,
                        notifyMessageSuccess: "Користувача успішно видалено!"
                    });
                    this.onShowSuccessAlert();
                } else {
                    this.onShowDangerAlert();
                }
            }
            if (action === "send") {
                this.setState({notifyMessageSuccess: "Повідомлення успішно надіслано!" });
                const sendResponse = await sendToUser(id, { message });
                if (sendResponse) {
                    this.onShowSuccessAlert();
                } else {
                    this.onShowDangerAlert();
                }
            }
        } catch (e) {
            this.onShowDangerAlert();
        }
      }

    render() {
        const columns = [
            {
                name: "chatId",
                label: "Чат ID",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { whiteSpace: 'nowrap', textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                },
            },
            {
                name: "fullName",
                label: "ПІБ",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: false,
                    sort: false,
                },
            },
            {
                name: "phone",
                label: "Телефон",
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: value => ({ style: { whiteSpace: 'nowrap', textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    
                },
            },
            {
                name: "partner",
                label: "Партнер",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: true,
                    sort: false,
                },
            },
            {
                name: "createdAt",
                label: "Дата створення",
                options: {
                    setCellProps: value => ({ style: { textAlign: 'center' } }),
                    setCellHeaderProps: value => ({ style: { textAlign: 'center' } }),
                    filter: false,
                    sort: false,
                },
            },
        ];
        if (!this.state.dataIsLoaded) return
            <div align="center">
                <Spinner animation="grow" />
            </div>
        return (
            <div>
                <Alert align="center" key="success" variant="success" show={this.state.nofifySuccessVisible}>
                    {this.state.notifyMessageSuccess}
                </Alert>
                <Alert align="center" key="danger" variant="danger" show={this.state.nofifyDangerVisible}>
                    {this.state.notifyMessageDanger}
                </Alert>
                <MUIDataTable
                    title={"Користувачі"}
                    data={this.state.items}
                    columns={columns}
                    options={muiTableoptions}
                />
            </div>
        );
    }
}

export default Users;