import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { FaRegTimesCircle, FaPencilAlt } from 'react-icons/fa';
import ConfirmModal from "./ConfirmModal"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class PolicyPoolTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmModal: false,
            showTextModal: false,
            deletionName: "",
            action: "",
            id: null,
        }
    }

    handleClick(action, id, message) {
        this.setState({ showConfirmModal: false });
        this.props.onClick(action, id, message);
    }

    handleConfirmModal(show, name, id, action) {
        this.setState({
            showConfirmModal: show,
            deletionName: name,
            action,
            id,
        });
    }

    renderEditPoolTooltip = (props) => (
        <Tooltip id="edit-pool-button-tooltip" {...props}>
            Редагувати
        </Tooltip>
    );
    
    renderDeletePoolTooltip = (props) => (
        <Tooltip id="delete-pool-button-tooltip" {...props}>
            Видалити
        </Tooltip>
    );

    render() {

        return (
            <div>
                <ConfirmModal
                    show={this.state.showConfirmModal}
                    onClickNo={() => this.setState({ showConfirmModal: false })}
                    onClickYes={(action, id, message) => this.handleClick(action, id, message)}
                    action={this.state.action}
                    id={this.state.id}
                    title="Підтвердження видалення"
                    body={"Ви впевнені що хочете видалити цей номер полісу"}
                />
                <Table striped bordered hover responsive="xl">
                    <thead align="center">
                        <tr>
                            <th>#</th>
                            <th>Тип</th>
                            <th>Назва</th>
                            <th>Статус</th>
                            <th>Дії</th>
                        </tr>
                    </thead>
                    <tbody align="center">
                        {
                            this.props.items.policies.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.type}</td>
                                    <td>{item.name}</td>
                                    <td>{item.status === "FREE" ? "Вільний" : "Зайнятий"}</td>
                                    <td align="center">
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 100, hide: 100 }}
                                            overlay={this.renderEditPoolTooltip()}
                                        >
                                            <span>
                                                <Button id={item._id} variant="primary" size="sm" onClick={() => this.props.onEditHandle(item.type, item.name, item._id)}>
                                                    <FaPencilAlt />
                                                </Button>{' '}
                                            </span>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 100, hide: 100 }}
                                            overlay={this.renderDeletePoolTooltip()}
                                        >
                                            <span>
                                                <Button id={item.id} variant="danger" size="sm" onClick={() => this.handleConfirmModal(true, item.firstName, item._id, "delete")}>
                                                    <FaRegTimesCircle />
                                                </Button>{' '}
                                            </span>
                                        </OverlayTrigger>



                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default PolicyPoolTable;
